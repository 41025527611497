import { StateName, StateNameType } from '../utils/States';
import { hormonalHealthDisclaimerStates } from './disclaimer.static';

export const PACKAGE_UNIQUE_CODE = {
  maleInfertility: 'maleInfertility',
  prostateEnlargement: 'prostateEnlargement',
  prostateCancerScreening: 'prostateCancerScreening',
  uti: 'uti',
  colonCancerScreening: 'colonCancerScreening',
  stdScreening: 'stdScreening',
  bladderCancerScreening: 'bladderCancerScreening',
  hormonalHealth: 'hormonalHealth',
  sexualHealth: 'sexualHealth',
} as const;

export type PackageUniqueCode =
  typeof PACKAGE_UNIQUE_CODE[keyof typeof PACKAGE_UNIQUE_CODE];

export type AilmentType = {
  image: string;
  name: string;
  subName: string;
  description: string;
  value: PackageUniqueCode;
  relatedPackages: PackageUniqueCode[];
  icon: string;
  selectedIcon: string;
  comingSoon?: boolean;
  disabled?: boolean;
  disclaimers?: PackageDisclaimer[];
};

export type PackageDisclaimer = {
  title: string;
  description: string;
  states?: StateNameType[];
};

export const AILMENTS: AilmentType[] = [
  {
    image: 'https://ik.imagekit.io/bastion/fertility-health-preparatory.jpg',
    name: 'Male Infertility',
    subName: '50% of infertility cases are due to male factors',
    description:
      'In-depth evaluation for male infertility factors. This includes specialist virtual visits, advanced diagnostic testing from home, and a tailored approach to treatment and fertility enhancement.',
    value: PACKAGE_UNIQUE_CODE.maleInfertility,
    relatedPackages: [
      PACKAGE_UNIQUE_CODE.sexualHealth,
      PACKAGE_UNIQUE_CODE.hormonalHealth,
    ],
    icon: 'https://ik.imagekit.io/bastion/icon/fertility-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/fertility-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Prostate Enlargement',
    subName: '1 in 2 males after age 45',
    description:
      'Comprehensive at-home assessment for prostate enlargement. This package includes virtual consultations with specialists, complete at-home testing kits, and personalized treatment plans if needed.',
    value: PACKAGE_UNIQUE_CODE.prostateEnlargement,
    relatedPackages: [
      PACKAGE_UNIQUE_CODE.bladderCancerScreening,
      PACKAGE_UNIQUE_CODE.prostateCancerScreening,
      PACKAGE_UNIQUE_CODE.uti,
      PACKAGE_UNIQUE_CODE.bladderCancerScreening,
    ],
    icon: 'https://ik.imagekit.io/bastion/icon/prostate-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/prostate-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Prostate Cancer Screening',
    subName: '1 in 8 males will be diagnosed with prostate cancer',
    description:
      'Thorough prostate cancer screening and care. Includes expert virtual consultations, at-home cancer screening tests, and personalized follow-up care plans based on screening outcomes.',
    value: PACKAGE_UNIQUE_CODE.prostateCancerScreening,
    relatedPackages: [
      PACKAGE_UNIQUE_CODE.bladderCancerScreening,
      PACKAGE_UNIQUE_CODE.prostateEnlargement,
      PACKAGE_UNIQUE_CODE.uti,
      PACKAGE_UNIQUE_CODE.bladderCancerScreening,
    ],
    icon: 'https://ik.imagekit.io/bastion/icon/prostate-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/prostate-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Sexual Health',
    subName: '1 in 3 males after age 18',
    description:
      'Complete sexual health assessment and support. Our package offers virtual consultations with healthcare experts, at-home testing, and customized treatment plans to address various sexual health concerns.',
    value: PACKAGE_UNIQUE_CODE.sexualHealth,
    relatedPackages: [PACKAGE_UNIQUE_CODE.hormonalHealth],
    icon: 'https://ik.imagekit.io/bastion/icon/ed.png',
    selectedIcon: 'https://ik.imagekit.io/bastion/icon/ed-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Hormonal Health (Low-T)',
    subName: '1 in 4 males after age 30',
    description:
      'Focused evaluation and management of hormonal health, including low testosterone. Features include specialist virtual consultations, comprehensive hormonal testing at home, and individualized treatment strategies.',
    value: PACKAGE_UNIQUE_CODE.hormonalHealth,
    relatedPackages: [PACKAGE_UNIQUE_CODE.sexualHealth],
    icon: 'https://ik.imagekit.io/bastion/icon/sexual-health.png',
    selectedIcon: 'https://ik.imagekit.io/bastion/icon/sexual-health-white.png',
    disclaimers: [
      {
        title: 'Please Note!',
        description:
          'In your state, due to controlled substance laws, we cannot prescribe testosterone.\n' +
          'However, there are other medications to treat low testosterone that you can discuss during your visit with Bastion urologists.',
        states: hormonalHealthDisclaimerStates,
      },
    ],
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Urinary Tract Infection (UTI)',
    subName: '1 in 7 males will be diagnosed with UTI',
    description:
      'Detailed assessment and treatment for UTIs. This package provides virtual specialist consultations, at-home testing for UTI diagnosis, and prompt, effective treatment plans as needed.',
    value: PACKAGE_UNIQUE_CODE.uti,
    relatedPackages: [],
    icon: 'https://ik.imagekit.io/bastion/icon/general-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/general-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Colon Cancer Screening',
    subName: '1 in 20 males will be diagnosed with colon cancer',
    description:
      'Colon cancer screening includes expert virtual consultations, at-home cancer screening tests, and personalized follow-up care plans based on screening outcomes.',
    value: PACKAGE_UNIQUE_CODE.colonCancerScreening,
    relatedPackages: [],
    icon: 'https://ik.imagekit.io/bastion/icon/general-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/general-health-white.png',
    comingSoon: false,
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'STD Screening',
    subName: '',
    description:
      'Detailed assessment and treatment for STD/STI. This package provides virtual specialist consultations, at-home testing for STD diagnosis, and prompt, effective treatment plans as needed.',
    value: PACKAGE_UNIQUE_CODE.stdScreening,
    relatedPackages: [],
    icon: 'https://ik.imagekit.io/bastion/icon/general-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/general-health-white.png',
    comingSoon: false,
  },

  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Bladder Cancer Screening',
    subName: '',
    description:
      'Comprehensive at-home prostate testing and assessment. Includes at-home test kits, specialist virtual visits, and if needed treatment plan.',
    value: PACKAGE_UNIQUE_CODE.bladderCancerScreening,
    relatedPackages: [],
    icon: 'https://ik.imagekit.io/bastion/icon/general-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/general-health-white.png',
    disabled: true,
  },
];

type SupportedPackageType = {
  packageId: PackageUniqueCode;
  coveredPercentage: number;
};

export const WIN_SUPPORTED_PACKAGES: SupportedPackageType[] = [
  {
    packageId: PACKAGE_UNIQUE_CODE.hormonalHealth,
    coveredPercentage: 100,
  },
];

export const WIN_STANDARD_CLIENTS_SUPPORTED_PACKAGES: SupportedPackageType[] = [
  {
    packageId: PACKAGE_UNIQUE_CODE.hormonalHealth,
    coveredPercentage: 100,
  },
];

export const WPP_SUPPORTED_PACKAGES: SupportedPackageType[] = [
  {
    packageId: PACKAGE_UNIQUE_CODE.hormonalHealth,
    coveredPercentage: 100,
  },
  {
    packageId: PACKAGE_UNIQUE_CODE.maleInfertility,
    coveredPercentage: 100,
  },
  {
    packageId: PACKAGE_UNIQUE_CODE.stdScreening,
    coveredPercentage: 100,
  },
];

export const WIN_PREMIER_CLIENTS_SUPPORTED_PACKAGES: SupportedPackageType[] = [
  {
    packageId: PACKAGE_UNIQUE_CODE.hormonalHealth,
    coveredPercentage: 100,
  },
  {
    packageId: PACKAGE_UNIQUE_CODE.maleInfertility,
    coveredPercentage: 100,
  },
  {
    packageId: PACKAGE_UNIQUE_CODE.stdScreening,
    coveredPercentage: 100,
  },
];

export const _SUPPORTED_PACKAGES: SupportedPackageType[] = [
  {
    packageId: PACKAGE_UNIQUE_CODE.hormonalHealth,
    coveredPercentage: 100,
  },
  {
    packageId: PACKAGE_UNIQUE_CODE.maleInfertility,
    coveredPercentage: 100,
  },
  {
    packageId: PACKAGE_UNIQUE_CODE.stdScreening,
    coveredPercentage: 100,
  },
];

export type OrganizationPackageMapType = {
  employerCode: string;
  supportedPackages?: SupportedPackageType[];
  hiddenPackages?: PackageUniqueCode[];
};

export const ORGANIZATIONS_PACKAGES_MAP: OrganizationPackageMapType[] = [
  {
    employerCode: 'win',
    supportedPackages: WIN_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'hsbc',
    supportedPackages: WIN_STANDARD_CLIENTS_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'bd',
    supportedPackages: WIN_STANDARD_CLIENTS_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'tel',
    supportedPackages: WIN_STANDARD_CLIENTS_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'lathamand-watkins',
    supportedPackages: WIN_STANDARD_CLIENTS_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'nixon-peabody',
    supportedPackages: WIN_STANDARD_CLIENTS_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'fisher-phillips',
    supportedPackages: WIN_PREMIER_CLIENTS_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'siemens-energy',
    supportedPackages: WIN_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'wpp',
    supportedPackages: WPP_SUPPORTED_PACKAGES,
  },
  {
    employerCode: 'radnet',
    hiddenPackages: [
      PACKAGE_UNIQUE_CODE.hormonalHealth,
      PACKAGE_UNIQUE_CODE.sexualHealth,
      PACKAGE_UNIQUE_CODE.maleInfertility,
    ],
  },
];
